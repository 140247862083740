import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Layout, Menu} from 'antd';
import * as Ant from 'antd';

const {Header: AntHeader, Content, Sider} = Layout;
const {SubMenu} = Menu;

const traceEntry = ({location, entries}) => {
  const name = location.pathname.split('/')[1] || 'welcome';

  for (const entry of entries) {
    if (entry.name === name) {
      return {entry};
    }
    if (entry.entries && entry.entries.length > 0) {
      const {entry: childEntry} = traceEntry({
        location,
        entries: entry.entries,
      });
      if (childEntry) {
        return {entry, child: childEntry};
      }
    }
  }
  return {entry: {name: '', label: ''}};
};

const CmsDashboard = ({
  location,
  entries,
  entryFunctionHandler,
  checkPermission,
  loginProfile,
  children,
  Header,
}) => {
  const {entry, child} = traceEntry({location, entries});
  const savedEntry = localStorage.getItem('selectedEntry');
  const savedParentEntry = localStorage.getItem('selectedParentEntry');
  const [selectedEntry, setSelectedEntry] = useState(() => {
    return savedEntry ? JSON.parse(savedEntry) : child || entry;
  });
  const [selectedParentEntry, setSelectedParentEntry] = useState(() => {
    return savedParentEntry && savedParentEntry !== 'undefined'
      ? JSON.parse(savedParentEntry)
      : entry;
  });
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (entry.name === 'welcome') {
      setSelectedEntry(entry);
    }
  }, [location, entries]);

  useEffect(() => {
    const trimmedPathname = location.pathname
      .replace(/^\/|\/$/g, '')
      .split('/')[0];
    checkPermission(`${trimmedPathname}/`);
    if (savedEntry && savedEntry !== 'undefined') {
      setSelectedEntry(JSON.parse(savedEntry));
    }

    if (savedParentEntry && savedParentEntry !== 'undefined') {
      setSelectedParentEntry(JSON.parse(savedParentEntry));
    }
  }, [location, checkPermission]);

  useEffect(() => {
    localStorage.setItem('selectedEntry', JSON.stringify(selectedEntry));
    localStorage.setItem(
      'selectedParentEntry',
      JSON.stringify(selectedParentEntry),
    );
  }, [selectedEntry, selectedParentEntry]);

  const handleSelectEntry = (entry, parentEntry) => {
    entryFunctionHandler(entry);
    checkPermission(`${entry.name}/`);
    setSelectedEntry(entry);
    setSelectedParentEntry(parentEntry);
  };

  const renderHeader = () => {
    if (typeof Header === 'string') {
      return <div className="site-title">Revtel Admin</div>;
    } else if (typeof Header === 'function') {
      // Component
      return <Header selectedEntry={selectedEntry} />;
    } else if (typeof Header === 'object') {
      // Element
      return Header;
    }
  };

  const renderMenuItems = (entries) => {
    if (!entries || entries.length === 0) {
      return null;
    }

    return entries.map((entry) => {
      if (entry.entries && entry.entries.length > 0) {
        return (
          <SubMenu key={entry.name} title={<span>{entry.label}</span>}>
            {entry.entries.map((e) => (
              <Menu.Item
                key={e.name}
                onClick={() => handleSelectEntry(e, entry)}>
                <span>{e.label}</span>
              </Menu.Item>
            ))}
          </SubMenu>
        );
      }

      return (
        <Menu.Item key={entry.name} onClick={() => handleSelectEntry(entry)}>
          {entry.icon || null}
          <span>{entry.label}</span>
        </Menu.Item>
      );
    });
  };

  const renderNavLink = () => {
    let output = selectedEntry.label;
    if (selectedParentEntry && selectedParentEntry.label !== '') {
      output = `${selectedParentEntry.label} / ${output}`;
    }
    return <h2 style={{letterSpacing: 2}}>{output}</h2>;
  };

  const renderContent = () => {
    try {
      return children({
        cms: {
          entry: selectedEntry,
        },
      });
    } catch (ex) {
      return <div>{selectedEntry.name}</div>;
    }
  };

  return (
    <Layout style={{minHeight: '100vh'}}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        {renderHeader()}
        <Menu
          theme="dark"
          defaultSelectedKeys={[selectedEntry.name]}
          defaultOpenKeys={[selectedParentEntry?.name]}
          mode="inline">
          {renderMenuItems(entries)}
        </Menu>
      </Sider>
      <Layout>
        <AntHeader style={{backgroundColor: 'white', padding: '0 15px'}}>
          <Ant.Row justify="end">
            <Ant.Col flex="auto">{renderNavLink()}</Ant.Col>
            <Ant.Col span={6}>
              <div style={{marginLeft: 10, textAlign: 'right'}}>
                {loginProfile.user.username}
                {loginProfile.staff_type === 'staff' && ' - 員工'}
              </div>
            </Ant.Col>
          </Ant.Row>
        </AntHeader>
        <Content>{renderContent()}</Content>
      </Layout>
    </Layout>
  );
};

const Avatar = styled.div`
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-color: #aaa;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
`;

export default CmsDashboard;
